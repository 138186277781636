import React from "react"
import Layout from "../components/layout"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core"
import Constants from "../config/constants"
import Grid from "@material-ui/core/Grid"
import Seo from "../components/seo"
import Testimonials from "../components/home/testimonials"
import { StaticImage } from "gatsby-plugin-image"
import Divider from "@material-ui/core/Divider"
import Link from "@material-ui/core/Link"
import BuildIcon from "@material-ui/icons/Build"
import Services from "../components/home/services"

const styles = makeStyles(theme => {
  return {
    firstContainer: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5)
    },
    mainLogo: {
      minWidth: 80
    },
    secondContainer: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      backgroundColor: "#293241",
      position: "relative"
    },
    thirdContainer: {
      paddingTop: theme.spacing(5)
    }
  }
})

const HomeContainer = () => {
  const classes = styles()
  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.firstContainer}>
        <Grid container spacing={3} justifyContent="center"
              alignItems="center">
          <Grid item xs={3} sm={2}>
            <StaticImage
              src='../images/logo.png'
              alt={`${Constants.siteMetadata.defaultTitle} Logo`}
              loading='eager'
              className={classes.mainLogo}
            />
          </Grid>
          <Grid item xs={9} sm={10}>
            <Typography variant="h3"
                        component="h1">
              {Constants.siteMetadata.defaultTitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h3" variant="h3" gutterBottom>
              We guarantee delivering high quality electrical services to your
              home and business.
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              - Yahya E. Zadeh (CEO)
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h5"
                        component="h5">
              Since 2006, our licensed and experienced professionals have been
              trusted with providing efficient solutions. The long history of
              our highly rated company with its many successful projects can
              guarantee your satisfaction.
              Square Electrical LLC serves Virginia, Washington DC, and
              Maryland.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Call us today for a free estimate: {" "}
              <Link
                href={`tel:${Constants.siteMetadata.phone}`}
                variant="body1"
                color="secondary"
              >
                {Constants.siteMetadata.phone}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" className={classes.secondContainer}
                 disableGutters>
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="center"
                alignItems="center">
            <Grid item xs={12}>
              <Typography component="h3" variant="h4">
                Locations
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6"
                          component="h1">
                <BuildIcon style={{ fontSize: 18 }}
                           color="secondary"/> Virginia: Fairfax, Arlington,
                Loudoun, Prince William county,
                City of Fairfax, Manassas, and Alexandria
                <br/>
                <BuildIcon style={{ fontSize: 18 }} color="secondary"/> District
                of Columbia
                <br/>
                <BuildIcon style={{ fontSize: 18 }}
                           color="secondary"/> Maryland: Prince George's and
                Montgomery county
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider/>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h3" variant="h4">
                Services
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Services/>
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Container maxWidth="lg" className={classes.thirdContainer}>
        <Testimonials />
        <span style={{ display: "none" }}>
          email address: square_e@objekt.click
        </span>
      </Container>
    </React.Fragment>
  )
}

export default function Home() {
  return (
    <Layout>
      <Seo/>
      <HomeContainer/>
    </Layout>
  )
}
