import React from "react"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Constants from "../../config/constants"
import { makeStyles } from "@material-ui/core"
import { AccountCircle, Star } from "@material-ui/icons"
import { Slide } from "react-awesome-reveal"

const styles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2)
  }
}))

export default function Testimonials() {
  const classes = styles()

  return (
    <Grid container spacing={3} justifyContent="center"
          alignItems="center">
      <Grid item xs={12}>
        <Typography component="h3" variant="h4">
          Customer Reviews
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Slide direction='up' damping={0.1} duration={500} fraction={1}
               triggerOnce cascade>
          {Constants.testimonials.map((testimonial, index) => (
            <Card variant='outlined' key={index}
                  className={index === Constants.testimonials.length - 1
                    ? ""
                    : classes.card}>
              <CardContent>
                <Grid container alignContent='center' alignItems='center'>
                  <Grid item sm={0} md={3}/>
                  <Grid item sm={12} md={9}>
                    <Star htmlColor='gold'/>
                    <Star htmlColor='gold'/>
                    <Star htmlColor='gold'/>
                    <Star htmlColor='gold'/>
                    <Star htmlColor='gold'/>
                  </Grid>
                  <Hidden smDown>
                    <Grid item sm={1} md={1}>
                      <AccountCircle style={{ fontSize: 60 }} color="primary"/>
                    </Grid>
                    <Grid item sm={3} md={2}>
                      <Typography variant="body1" color="primary">
                        {testimonial.name}
                      </Typography>
                    </Grid>
                    <Grid item sm={8} md={9}>
                      <Typography variant="body1" color="primary">
                        {testimonial.quote}
                      </Typography>
                    </Grid>
                  </Hidden>
                  <Hidden mdUp>
                    <Grid item sm={12}>
                      <Typography variant="body1" color="primary" gutterBottom>
                        {testimonial.quote}
                      </Typography>
                      <Typography component="subtitle1" color="primary">
                        - {testimonial.name}
                      </Typography>
                    </Grid>
                  </Hidden>
                </Grid>
              </CardContent>
            </Card>
          ))}
        </Slide>
      </Grid>
    </Grid>
  )
}
