import React from "react"
import { makeStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { Fade } from "react-awesome-reveal"
import Constants from "../../config/constants"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

const styles = makeStyles(theme => ({
  body: {
    [theme.breakpoints.down("md")]: {
      height: 210
    },
    [theme.breakpoints.up("md")]: {
      height: 290
    },
    [theme.breakpoints.up("lg")]: {
      height: 250
    }
  },
  media: {
    height: 200
  }
}))

const getImg = (images, imgName) => {
  return images.edges.find((edge) => edge.node.parent.name === imgName)
}

export default function Services() {
  const classes = styles()
  let data = useStaticQuery(graphql`
  {
    images: allImageSharp {
      edges {
        node {
          gatsbyImageData
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
    services: allJsonDataJson {
      edges {
        node {
          services {
            body
            media
            title
          }
        }
      }
    }
  }
  `)

  let { images, services: serviceNode } = data
  let services = serviceNode.edges[0].node.services

  return (
    <Fade duration={2000} triggerOnce cascade>
      <Grid container spacing={3}>
        {services.map((service, index) => {
          let imageName = service.media
          let image = getImg(images, imageName)
          return (<Grid item xs={12} md={4} key={index}>
            <Card>
              {image && <GatsbyImage
                image={getImage(image.node)}
                alt={`${Constants.siteMetadata.defaultTitle}`}
                className={classes.media}
              />}
              <CardContent className={classes.body}>
                <Typography gutterBottom variant="h5" component="h2"
                            color='primary'>
                  {service.title}
                </Typography>
                <Box component="div"
                     my={2}
                     textOverflow="ellipsis"
                     overflow="hidden">
                  <Typography variant="body2" component="p" color='primary'>
                    {service.body}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>)
        })}
      </Grid>
    </Fade>
  )
}